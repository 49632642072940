import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Container, Grid, Header, Segment } from 'buildingBlocks';
import PageLoading from 'components/PageLoading';
import { HTTP_STATUS } from 'constantsBase';
import PageForbidden from 'containers/403';
import { isAdmin } from 'containers/User/utils';
import { GlobalState } from 'reducers';
import { Lambda } from 'utils/copilotAPI';
import { isAdminOrQAGlobal } from 'utils/functionHelpers';
import { EnvironmentVariables, User } from 'utils/types';
import WHITELIST_IP_STYLES from './style';
import WhitelistIpModal from './WhitelistIPModal';

const { headerContainer, headerText, headerDesc, container, btnContainer, submitBtn } = WHITELIST_IP_STYLES;

export const WHITELIST_IP_PAGE_NAME = 'Whitelist IP Address';
const LAMBDA_FUNCTION_NAME = 'VpnWanIpFiltering-prod-addMyWanIp';

const WhitelistIP = () => {
  const [awaitingResp, setAwaitingResp] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
  const navigate = useNavigate();

  const loginState = useSelector<GlobalState>((state) => state.login) as { user: User, env: string };
  const { user, env } = loginState;
  const envNotLoaded = _.isNil(env);
  const canAccessPage = isAdminOrQAGlobal(user);
  const isAdminUser = isAdmin(user);

  useEffect(() => {
    if (!envNotLoaded && !_.isEqual(env, EnvironmentVariables.dev)) {
      navigate('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [env]);

  const handleSubmit = async () => {
    setAwaitingResp(true);
    const lambdaRes = await Lambda.invokeLambdaFunction(LAMBDA_FUNCTION_NAME);
    const resPayload = _.get(lambdaRes, 'data.Payload', {});
    const resStatus = _.get(resPayload, 'statusCode');
    const resMessage = _.get(resPayload, 'userMessage', 'There was an error. Please try again in a few minutes.');
    if (resStatus === HTTP_STATUS.VALID) {
      setShouldRedirect(true);
    }
    setModalMessage(resMessage);
    setAwaitingResp(false);
  };

  if (envNotLoaded) {
    return <PageLoading />;
  }

  return (canAccessPage
    ? (
      <>
        {awaitingResp && <PageLoading />}
        {modalMessage && (
          <WhitelistIpModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
            shouldRedirect={shouldRedirect}
          />
        )}
        <Container>
          <DocumentTitle title={WHITELIST_IP_PAGE_NAME} />
          <div style={headerContainer}>
            <Header as="h2" content={WHITELIST_IP_PAGE_NAME} style={headerText} />
            <p style={headerDesc}>
              Whitelist your IP address so that you can use the VPN.
            </p>
          </div>
          <Segment>
            <Grid style={container}>
              <Grid.Row>
                <Grid.Column style={btnContainer}>
                  <Button
                    style={submitBtn}
                    onClick={handleSubmit}
                    disabled={!isAdminUser}
                    primary
                  >
                    Whitelist My IP Address
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </>
    ) : (
      <PageForbidden button />
    )
  );
};

export default WhitelistIP;
