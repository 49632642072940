import { FLIGHT_EXTERNAL_TYPE } from 'constantsBase';

// Xandr URLs
export const APN_BASE_URL = 'console.appnexus.com' as const;
export const XANDR_PLATFORM_BASE_URL = 'platform.xandr.com' as const;
export const XANDR_INVEST_BASE_URL = 'invest.xandr.com' as const;
export const XANDR_MONETIZE_BASE_URL = 'monetize.xandr.com' as const;

// TTD URL
export const TTD_BASE_URL = 'desk.thetradedesk.com' as const;

// DBM URL
export const DBM_BASE_URL = 'displayvideo.google.com' as const;

// AMZN URL
export const AMZN_BASE_URL = 'advertising.amazon.com' as const;
export const AMZN_BASE_UK_URL = 'advertising.amazon.co.uk' as const;

// Copilot URLs
export const DEV_URL = 'dev-fly.copilot.choreograph.com' as const;
export const RELEASE_URL = 'release-web.copilot-aws.com' as const;
export const PROD_URL = 'fly.copilot.ai' as const;
export const DEMAND_URL = 'copilot.xaxisdemand.com' as const;
export const DEMO_URL = 'demo.copilot.ai' as const;
export const LOCAL_URL = 'localhost:3000' as const;
export const DEV_SITE_URL = 'devsite{#}qa.xaxisdemand.com' as const;

export const MAX_AUTHENTICATION_WAIT_TIME = 10000 as const;

export const XANDR_DISPLAY_NAME = 'Xandr';

export const EXTERNAL_TYPES_SUPPORTING_SATELLITE_STRATEGY_CREATION = [
  FLIGHT_EXTERNAL_TYPE.apnLineItem.id,
  FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id,
  FLIGHT_EXTERNAL_TYPE.ttdCampaign.id,
  FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id,
  FLIGHT_EXTERNAL_TYPE.ttdMegagon.id,
  FLIGHT_EXTERNAL_TYPE.amznOrder.id,
];
