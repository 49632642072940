import { CSSProperties } from 'react';
import { COPILOT_LAYOUT, COPILOT_TYPOGRAPHY } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;
const { HEADING } = COPILOT_TYPOGRAPHY;

const WHITELIST_IP_STYLES: { [key: string]: CSSProperties } = {
  headerContainer: {
    marginTop: SPACING[40],
    marginBottom: SPACING[16],
  },
  headerText: {
    ...HEADING.H2,
    margin: 0,
  },
  headerDesc: {
    marginTop: SPACING[8],
    marginBottom: SPACING[16],
  },
  container: {
    minHeight: 200,
  },
  btnContainer: {
    textAlign: 'center',
    margin: 'auto',
  },
  submitBtn: {
    borderRadius: SPACING[4],
    fontSize: '24px',
    fontWeight: 600,
    padding: '12px 24px',
    textTransform: 'capitalize',
  },
  modalCloseIcon: {
    position: 'absolute',
    right: SPACING[16],
    top: SPACING[12],
    fontSize: 24,
    fontWeight: 'normal',
    cursor: 'pointer',
  },
  modalMsgContainer: {
    padding: '32px 24px 24px',
    fontSize: '16px',
    textAlign: 'center',
  },
  modalDoneBtn: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    position: 'absolute',
    right: SPACING[16],
    bottom: SPACING[12],
    textTransform: 'capitalize',
    padding: '4px 16px',
    borderRadius: SPACING[4],
    fontWeight: 500,
  },
};

export default WHITELIST_IP_STYLES;
