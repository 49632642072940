// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: rgb(238, 238, 238) !important;
  overflow-x: hidden !important;
}

* {
  box-sizing: border-box;
}

.ag-overlay-loading-wrapper {
  display: flex;
  padding-top: 5rem;
  justify-content: center;
  align-items: baseline;
}

a:hover {
  color: var(--wpp-primary-color-500) !important;
}

.highcharts-container {
  position: inherit !important;
}

.highcharts-tooltip {
  z-index: 9;
}

.wpp-list-item {
  width: 100%;
}
.wpp-list-item::part(item), .wpp-list-item::part(info-wrapper), .wpp-list-item::part(body-wrapper) {
  width: 100%;
}

@keyframes fadeMessage {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
:root {
  --wpp-portal-max-width: 100%;
}

.ui.message {
  width: auto !important;
}

.wpp-sort-button::part(button) {
  height: 34px;
}

.ag-cell {
  display: flex;
  height: auto;
}

.ag-header-cell-text {
  font-family: "Inter";
  font-size: 14px;
  color: var(--wpp-grey-color-1000);
}`, "",{"version":3,"sources":["webpack://./src/wppGlobalStyle.scss"],"names":[],"mappings":"AAAA;EACE,+CAAA;EAEA,6BAAA;AAAF;;AAIA;EACE,sBAAA;AADF;;AAIA;EACE,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,qBAAA;AADF;;AAIA;EACE,8CAAA;AADF;;AAIA;EACE,4BAAA;AADF;;AAGA;EACE,UAAA;AAAF;;AAGA;EACE,WAAA;AAAF;AACE;EACE,WAAA;AACJ;;AAGA;EACE;IAAM,UAAA;EACN;EAAA;IAAI,UAAA;EAGJ;AACF;AADA;EACE,4BAAA;AAGF;;AAAA;EACE,sBAAA;AAGF;;AAAA;EACE,YAAA;AAGF;;AAAA;EACE,aAAA;EACA,YAAA;AAGF;;AAAA;EACE,oBAAA;EACA,eAAA;EACA,iCAAA;AAGF","sourcesContent":["body {\n  background-color: rgb(238, 238, 238) !important;\n  // Added this due to WppModal component. it is adding overflow: auto property. due to which horizontal scroll bar is showing up.\n  overflow-x: hidden !important;\n}\n\n// Added for WppGrid so the columns will be align\n* {\n  box-sizing: border-box;\n}\n\n.ag-overlay-loading-wrapper {\n  display: flex;\n  padding-top: 5rem;\n  justify-content: center;\n  align-items: baseline;\n}\n\na:hover {\n  color: var(--wpp-primary-color-500) !important;\n}\n\n.highcharts-container {\n  position: inherit !important;\n}\n.highcharts-tooltip {\n  z-index: 9;\n}\n\n.wpp-list-item {\n  width: 100%;\n  &::part(item), &::part(info-wrapper), &::part(body-wrapper) {\n    width: 100%;\n  }\n}\n\n@keyframes fadeMessage {\n  from {opacity: 1;}\n  to {opacity: 0;}\n}\n\n:root {\n  --wpp-portal-max-width: 100%;\n}\n\n.ui.message {\n  width: auto !important;\n}\n\n.wpp-sort-button::part(button) {\n  height: 34px;\n}\n\n.ag-cell {\n  display: flex;\n  height: auto;\n}\n\n.ag-header-cell-text {\n  font-family: 'Inter';\n  font-size: 14px;\n  color: var(--wpp-grey-color-1000);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
