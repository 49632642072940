import { DSP } from 'constantsBase';

export const COPILOT_COLORS = {
  grey: '#EEEEEE',
  heavyGrey: '#4A4A4A',
  mediumGrey: 'rgba(74, 74, 74, 0.5)',
  graphGrey: '#979797',
  orange: '#FF6E31',
  lightYellow: 'rgba(255, 198, 26, 0.1)',
  // https://www.figma.com/file/jtUqNEuvQuyB011pLsdQet/Design-System?node-id=1200%3A35
  NEW_DESIGN_SYSTEM: {
    // once data viz palette is finalized, we will move orange and purple to their own section
    orange: '#E3A746',
    purple: '#9130E3',
    NEUTRALS: {
      N0_WHITE: '#FFFFFF',
      N50_ALABASTER: '#FAFAFA',
      N100_CLOUD: '#F5F5F5',
      N200_INSIGHTS: '#D5DBDD',
      N150_GALLERY: '#F0F0F0',
      N200_LINEN: '#EBEAEB',
      N300_ALTO: '#BBC2C4',
      N400_GRANITE: '#858B8D',
      N500_NEVADA: '#666E70',
      N600_ABBEY: '#535D5F',
      N700_SLATE: '#394446',
      N750_INSIGHTS: '#EAEFF0',
      N800_MATTER: '#212123',
      N900_BLACK: '#000000',
    },
    METTLES: {
      M50_FLASH: '#F7F8FE',
      M100_HAZE: '#E9EEF5',
      M200_CATSKILL: '#E2E8F1',
      M300_FOG: '#DBE2EE',
      M400_CHATEAU: '#AFB5BE',
      M700_OSLO: '#83888F',
      M900_TROUT: '#424447',
    },
    BLUES: {
      B50_SEA: '#EDEFFC',
      B100_FISH: '#B5C0F4',
      B200_LAGOON: '#90A1EE',
      B300_SKY: '#6B81E9',
      B500_WAVE: '#4662E3',
      B600_INDIGO: '#3F58CC',
      B700_ORIENT: '#31459F',
      B900_SHERPA: '#233172',
    },
    PURPLES: {
      P50_LILAC: '#DAD7EA',
      P100_LOGAN: '#B5AED4',
      P200_AMETHYST: '#9086BF',
      P300_LAVENDER: '#6B5DA9',
      P500_REX: '#463594',
      P700_MINSK: '#382A76',
      P900_PORT: '#231B4A',
    },
    REDS: {
      R50_VISAGE: '#FFF1F0',
      R100_KRANG: '#FFCCC7',
      R200_BLUSH: '#FFA39E',
      R300_ROSE: '#FF7875',
      R500_WATERMELON: '#FF4D4F',
      R700_VALENTINE: '#A8071A',
      R900_CHAMPAGNE: '#820014',
    },
    PINKS: {
      P50_CHERUB: '#FDECF6',
      P100_CHANTILLY: '#F9C7E3',
      P200_ILLUSION: '#F6A2D1',
      P300_PERSIAN: '#F27CBE',
      P500_MAX: '#EC44A2',
      P700_BERRY: '#A53071',
      P900_WINE: '#5E1B41',
    },
    YELLOWS: {
      Y50_DAWN: '#FFFBE6',
      Y100_GLOW: '#FFE58F',
      Y200_MUSTARD: '#FFD666',
      Y300_ORANGE: '#FFC53D',
      Y400_INSIGHTS_ORANGE: '#E36535',
      Y600_INSIGHTS_LIGHT_ORANGE: '#FFE6DD',
      Y500_GOLD: '#FAAD14',
      Y700_METAL: '#AD6800',
      Y900_BRONZE: '#613400',
    },
    TEALS: {
      T50_FOAM: '#EEFDFB',
      T100_CHARLOTTE: '#BBF8EF',
      T200_SPRAY: '#87F2E2',
      T300_DATA: '#54EDD6',
      T500_KEPPEL: '#3BA696',
      T700_LOCHINVAR: '#225F56',
      T900_DIANNE: '#194740',
    },
    GREENS: {
      G50_CANE: '#F6FFED',
      G100_REEF: '#D9F7BE',
      G200_SULU: '#B7EB8F',
      G300_ATLANTIS: '#73D13D',
      G500_LIMA: '#52C14A',
      G700_SLIME: '#237804',
      G900_PINE: '#135200',
    },
    SANGUINES: {
      S50_ORINOCO: '#F7FDE0',
      S100_MIMOSA: '#EEFBC1',
      S200_TEXAS: '#E6FAA3',
      S300_MINDARO: '#DDF884',
      S500_VOLT: '#D5F665',
      S700_SUSHI: '#95AC47',
      S900_WOODLAND: '#556228',
    },
    DSP: {
      [DSP.APN.id]: {
        dark: '#FC5047',
        light: '#FFDDDA',
      },
      [DSP.TTD.id]: {
        dark: '#1D9AD6',
        light: '#D3EBF6',
      },
      [DSP.DBM.id]: {
        dark: '#0F9D58',
        light: '#CEEADE',
      },
      [DSP.AMZN.id]: {
        dark: '#FF9900',
        light: '#FFEBCD',
      },
      [DSP.MULTIPLE.id]: {
        dark: '#4662E3',
        light: '#EDEFFC',
      },
      [DSP.WALMART.id]: {
        dark: '#041E42',
        light: '#041E4233',
      },
    },
  },
  WPP: {
    blue100: '#E0EBFF',
    blue500: '#0014CC',
    grey0: '#697077',
    grey100: '#F8F9FB',
    grey200: '#F4F5F7',
    grey1000: '#121619',
  },
};

export const COPILOT_TYPOGRAPHY = {
  HEADING: {
    JUMBO: {
      fontSize: 48,
      lineHeight: '58px',
      fontWeight: 600,
    },
    H1: {
      fontSize: 36,
      lineHeight: '44px',
      fontWeight: 600,
    },
    H2: {
      fontSize: 30,
      lineHeight: '38px',
      fontWeight: 600,
    },
    H3: {
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: 600,
    },
    H4: {
      fontSize: 20,
      lineHeight: '28px',
      fontWeight: 600,
    },
    H5: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 600,
    },
    H6: {
      fontSize: 14,
      lineHeight: '22px',
      fontWeight: 600,
      margin: 0,
    },
  },
  BODY: {
    LARGE: {
      fontSize: 18,
      lineHeight: '28px',
      fontWeight: 400,
    },
    MEDIUM: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 400,
    },
    SMALL: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 400,
    },
  },
  DESCRIPTION: {
    REGULAR: {
      fontSize: 12,
      lineHeight: '20px',
      fontWeight: 400,
    },
    BOLD: {
      fontSize: 12,
      lineHeight: '20px',
      fontWeight: 600,
    },
  },
};

export const COPILOT_LAYOUT = {
  SPACING: {
    2: 2,
    4: 4,
    8: 8,
    12: 12,
    16: 16,
    20: 20,
    24: 24,
    32: 32,
    40: 40,
    48: 48,
    56: 56,
    60: 60,
    64: 64,
  },
};
