import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, Modal } from 'buildingBlocks';
import WHITELIST_IP_STYLES from './style';

const { modalCloseIcon, modalMsgContainer, modalDoneBtn } = WHITELIST_IP_STYLES;

type WhitelistIPModalProps = {
  modalMessage: string
  setModalMessage: (x: any) => void
  shouldRedirect: boolean
};

const WhitelistIpModal = (props: WhitelistIPModalProps) => {
  const { modalMessage, setModalMessage, shouldRedirect } = props;

  const navigate = useNavigate();

  const handleClose = () => {
    setModalMessage('');
    if (shouldRedirect) {
      navigate('/');
    }
  };

  return (
    <Modal
      open={!!modalMessage}
      size="small"
    >
      <Icon
        name="times"
        style={modalCloseIcon}
        onClick={handleClose}
      />
      <Modal.Content>
        <div style={{ ...modalMsgContainer, ...(shouldRedirect && { padding: '32px 24px 36px' }) }}>
          {modalMessage}
        </div>
      </Modal.Content>
      {shouldRedirect && (
        <Button
          style={modalDoneBtn}
          onClick={handleClose}
          primary
        >
          Done
        </Button>
      )}
    </Modal>
  );
};

export default WhitelistIpModal;
